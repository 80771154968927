import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-magic-items"></a><h2>Epic Magic Items
    </h2>
    <a id="epic-rings"></a><h3>RINGS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a id="nonepic-magic-item"></a><h6>NONEPIC MAGIC ITEM </h6>
    <a id="ring-of-universal-energy-resistance-minor"></a><span style={{
      "fontWeight": "bold"
    }}>Universal Energy Resistance,
Minor:</span> This ring functions as a ring of minor energy resistance
for all types of energy: fire, cold, electricity, acid, and sonic. When
the wearer would normally take such damage, subtract 10 points of
damage per round from the amount before applying. This is a nonepic
magic item. 
    <span style={{
      "fontStyle": "italic"
    }}>Caster Level:</span> 15th; <span style={{
      "fontStyle": "italic"
    }}>Prerequisites:</span> Forge Ring,
protection from energy; <span style={{
      "fontStyle": "italic"
    }}>Market Price:</span>
84,000 gp.
    <a id="table-epic-rings"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "500px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>d%</th>
          <th style={{
            "width": "35%"
          }}>Ring</th>
          <th style={{
            "width": "20%"
          }}>Market Price</th>
        </tr>
        <tr className="odd-row">
          <td>01-08</td>
          <td>Universal energy resistance, greater
          </td>
          <td>308,000 gp</td>
        </tr>
        <tr>
          <td>09-13</td>
          <td>Energy immunity (acid)</td>
          <td>240,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>14-15</td>
          <td>Energy immunity (cold)</td>
          <td>240,000 gp</td>
        </tr>
        <tr>
          <td>19-23</td>
          <td>Energy immunity (electricity)</td>
          <td> 240,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>24-28</td>
          <td>Energy immunity (fire)</td>
          <td>240,000 gp</td>
        </tr>
        <tr>
          <td>29-33</td>
          <td>Energy immunity (sonic)</td>
          <td>240,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>34-38</td>
          <td>Adamant law</td>
          <td>250,000 gp</td>
        </tr>
        <tr>
          <td>39-43</td>
          <td>Chaotic fury</td>
          <td> 250,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>44-48</td>
          <td> Epic wizardry (V)</td>
          <td>250,000 gp</td>
        </tr>
        <tr>
          <td>49-53</td>
          <td> Ineffable evil</td>
          <td>250,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>54-58</td>
          <td>Virtuous good</td>
          <td>250,000 gp</td>
        </tr>
        <tr>
          <td>59-63</td>
          <td>Rapid healing</td>
          <td>300,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>64-68</td>
          <td> Sequestering</td>
          <td>300,000 gp</td>
        </tr>
        <tr>
          <td>69-72</td>
          <td>Epic wizardry (VI) </td>
          <td>360,000 gp </td>
        </tr>
        <tr className="odd-row">
          <td>73-76</td>
          <td> Ironskin </td>
          <td> 400,000 gp</td>
        </tr>
        <tr>
          <td>77-80</td>
          <td> Epic wizardry (VII)&nbsp; 
          </td>
          <td> 490,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>81-83</td>
          <td>Weaponbreaking</td>
          <td> 600,000 gp</td>
        </tr>
        <tr>
          <td className="last-row">84-86</td>
          <td className="last-row">Epic wizardry (VIII)</td>
          <td className="last-row">640,000 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>87-89</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>&nbsp;
Epic protection +6</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>720,000
gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>90-92</td>
          <td style={{
            "verticalAlign": "top"
          }}>Epic wizardry (IX) 
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>810,000 gp </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>93-95</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Epic
protection +7</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>980,000
gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>96-97</td>
          <td style={{
            "verticalAlign": "top"
          }}>Epic protection +8</td>
          <td style={{
            "verticalAlign": "top"
          }}>1,280,000 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>98</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Epic
protection +9</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1,620,000
gp </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>99</td>
          <td style={{
            "verticalAlign": "top"
          }}>Epic protection +10</td>
          <td style={{
            "verticalAlign": "top"
          }}>2,000,000 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>100</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Universal
energy immunity</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>2,160,000
gp
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-ring-description"></a><h6>Epic Ring Descriptions </h6>
Standard epic rings are described below.
    <a id="ring-of-adamant-law"></a><p><span style={{
        "fontWeight": "bold"
      }}>Adamant Law:</span>&nbsp; The
wearer of this ring is constantly sheathed in a shield of law effect.
It bestows one negative level on any chaotic creature that puts it on.
The negative level remains as long as the ring is worn and disappears
when the ring is removed. This negative level never results in actual
level loss, but it cannot be overcome in any way (including restoration
spells) while the ring is worn.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 15th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, shield of law, creator must be lawful; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 250,000 gp. </p>
    <a id="ring-of-chaotic-fury"></a><p><span style={{
        "fontWeight": "bold"
      }}>Chaotic Fury:</span> The
wearer of this ring is constantly sheathed in a cloak of chaos effect.
It bestows one negative level on any lawful creature that puts it on.
The negative level remains as long as the ring is worn and disappears
when the ring is removed. This negative level never results in actual
level loss, but it cannot be overcome in any way (including restoration
spells) while the ring is worn.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 15th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, cloak of chaos, creator must be chaotic; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 250,000 gp. </p>
    <a id="ring-of-energy-immunity"></a><p><span style={{
        "fontWeight": "bold"
      }}>Energy Immunity:</span> This
band continually provides the wearer with immunity to a single type of
energy: fire, cold, electricity, acid, or sonic. The wearer takes no
damage from the energy of the specific type.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, protection from energy;<span style={{
        "fontStyle": "italic"
      }}>
Market Price:</span> 240,000 gp. Epic </p>
    <a id="ring-of-protection"></a><p><span style={{
        "fontWeight": "bold"
      }}>Protection:</span> This ring
offers continual magical protection in the form of a deflection bonus
to Armor Class of +6 or higher.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th: <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, shield of faith, creator&#8217;s caster level must be three times
the ring&#8217;s bonus; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
720,000 gp (+6), 980,000 gp (+7), 1,280,000 gp (+8), 1,620,000 gp (+9),
2,000,000 gp (+10). </p>
    <a id="ring-of-epic-wizardry"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Wizardry:</span> Like
the ring of wizardry, this ring comes in a variety of types useful only
to arcane spellcasters. The wearer&#8217;s arcane spells per day are doubled
for one particular spell level. An epic ring of wizardry V doubles
5th-level spells, an epic ring of wizardry VI doubles 6th-level spells,
an epic ring of wizardry VII doubles 7th-level spells, an epic ring of
wizardry VIII doubles 8th-level spells, and an epic ring of wizardry IX
doubles 9th-level spells. Bonus spells from high ability scores, school
specialization, or any other source are not doubled.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd (epic
wizardry V), 26th (epic wizardry VI), 29th (epic wizardry VII), 32nd
(epic wizardry VIII), 35th (epic wizardry IX); <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, wish; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
250,000 gp (epic wizardry V), 360,000 gp (epic wizardry VI), 490,000 gp
(epic wizardry VII), 640,000 gp (epic wizardry VIII), 810,000 gp (epic
wizardry IX). </p>
    <a id="ring-of-ineffable-evil"></a><p><span style={{
        "fontWeight": "bold"
      }}>Ineffable Evil:</span> The
wearer of this ring is constantly sheathed in an unholy aura effect. It
bestows one negative level on any good creature that puts it on. The
negative level remains as long as the ring is worn and disappears when
the ring is removed. This negative level never results in actual level
loss, but it cannot be overcome in any way (including restoration
spells) while the ring is worn.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 15th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, unholy aura, creator must be evil; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 250,000 gp. </p>
    <a id="ring-of-ironskin"></a><p><span style={{
        "fontWeight": "bold"
      }}>Ironskin:</span> This ring
grants its wearer damage reduction 15/adamantine.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, iron body; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
400,000 gp. </p>
    <a id="ring-of-rapid-healing"></a><p><span style={{
        "fontWeight": "bold"
      }}>Rapid Healing:</span> This
ring grants a living wearer fast healing 3. It must be worn for 24
hours before its powers activate, and if removed it will not function
again until it has been worn for 24 hours by the same individual.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, regenerate; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
300,000 gp. </p>
    <a id="ring-of-sequestering"></a><p><span style={{
        "fontWeight": "bold"
      }}>Sequestering:</span> This
ring becomes invisible when worn. Upon command, the wearer gains the
benefits of a sequester spell (though he or she does not become
comatose as normal for the spell).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, sequester; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
300,000 gp. </p>
    <a id="ring-of-universal-energy-immunity"></a><p><span style={{
        "fontWeight": "bold"
      }}>Universal Energy Immunity:</span>
This ring functions as a ring of energy immunity for all types of
energy- fire, cold, electricity, acid, and sonic. The wearer takes no
damage from energy of any of these types.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, protection from energy; <span style={{
        "fontStyle": "italic"
      }}>Market
Price:</span> 2,160,000 gp. </p>
    <a id="ring-of-universal-energy-resistance-greater"></a><p><span style={{
        "fontWeight": "bold"
      }}>Universal Energy Resistance,
Greater:</span> This ring functions as a ring of greater energy
resistance for all types of energy-fire, cold, electricity, acid, and
sonic. When the wearer would normally take such damage, subtract 30
points of damage per round from the amount before applying.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, protection from energy; <span style={{
        "fontStyle": "italic"
      }}>Market
Price:</span> 308,000 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to
Create:</span> 154,000 gp + 13,080 XP. </p>
    <a id="ring-of-virtuous-good"></a><p><span style={{
        "fontWeight": "bold"
      }}>Virtuous Good:</span> The
wearer of this ring is constantly sheathed in a holy aura effect. It
bestows one negative level on any evil creature that puts it on. The
negative level remains as long as the ring is worn and disappears when
the ring is removed. This negative level never results in actual level
loss, but it cannot be over-come in any way (including restoration
spells) while the ring is worn.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 15th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, holy aura, creator must be good; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 250,000 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 125,000 gp + 12,500
XP. </p>
    <a id="ring-of-weaponbreaking"></a><p><span style={{
        "fontWeight": "bold"
      }}>Weaponbreaking:</span> A ring
of weaponbreaking is identical to a ring of ironskin, and has one
additional power. Any weapon that successfully strikes the wearer must
also make a Fortitude saving throw (DC 20) or be shattered into pieces.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Forge Ring, Forge
Epic Ring, iron body, shatter; <span style={{
        "fontStyle": "italic"
      }}>Market
Price:</span> 600,000 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to
Create:</span> 300,000 gp + 16,000 XP. </p>
    <a id="ring-of-epic-psionics"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Psionics:</span> This
special crystal ring comes in a variety of types useful only to psionic
characters (characters who have power points per day). The wearer&#8217;s
total power points per day are increased, depending on the ring. The
points granted are not bonus points-while the ring is worn, it actually
increases the wearer&#8217;s points per day, but a night&#8217;s rest is required
before gaining access to the increased power point per day total.
(Power points are not actually stored in the ring, as would be the case
for a crystal capacitor. Instead, the ring grants power points by
magnifying the manifester&#8217;s own power.)
      <p>{`A ring of epic psionics V increases the wearer`}{`’`}{`s daily power points by
43 points, a ring of epic psionics VI grants 63 power points, a ring of
epic psionics VII grants 87 power points, a ring of epic psionics VIII
grants 115 points, and a ring of epic psionics IX grants 147 power
points.`}</p>
      <span style={{
        "fontStyle": "italic"
      }}>Manifester Level:</span> 23rd
(epic psionics V), 26th (epic psionics VI), 29th (epic psionics VII),
32nd (epic psionics VIII), 35th (epic psionics IX); <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Universal
Item, Craft Epic Universal Item, great emulation; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 250,000 gp (epic
psionics V), 360,000 gp (epic psionics VI), 490,000 gp (epic psionics
VII), 640,000 gp (epic psionics VIII), 810,000 gp (epic psionics IX). </p>
    <a id="epic-rods"></a><h3>RODS
    </h3>
    <a id="table-epic-rods"></a><table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "500px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>d%</th>
          <th style={{
            "width": "35%"
          }}>Rod</th>
          <th style={{
            "width": "20%"
          }}>Market Price</th>
        </tr>
        <tr className="odd-row">
          <td>01-08</td>
          <td>Epic spellcaster
          </td>
          <td>245,000 gp</td>
        </tr>
        <tr>
          <td>09-16</td>
          <td>Nightmares</td>
          <td>284,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>17-24</td>
          <td>Epic splendor</td>
          <td>297,000 gp</td>
        </tr>
        <tr>
          <td>25-31</td>
          <td>The path</td>
          <td>306,870 gp</td>
        </tr>
        <tr className="odd-row">
          <td>32-38</td>
          <td>Epic cancellation</td>
          <td>330,000 gp</td>
        </tr>
        <tr>
          <td>39-45</td>
          <td>Epic negation</td>
          <td>446,000 gp </td>
        </tr>
        <tr className="odd-row">
          <td>46-51</td>
          <td>Besiegement</td>
          <td>447,745 gp</td>
        </tr>
        <tr>
          <td>52-57</td>
          <td>Fortification</td>
          <td>465,665 gp</td>
        </tr>
        <tr className="odd-row">
          <td>58-63</td>
          <td>Epic rulership</td>
          <td>575,000 gp</td>
        </tr>
        <tr>
          <td>64-69</td>
          <td>Invulnerability</td>
          <td>600,000 gp&nbsp; 
          </td>
        </tr>
        <tr className="odd-row">
          <td>70-75</td>
          <td>Paradise</td>
          <td>610,000 gp </td>
        </tr>
        <tr>
          <td>76-80</td>
          <td>Restless death</td>
          <td>625,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>81-85 
          </td>
          <td>Excellent magic</td>
          <td>650,000 gp &nbsp; 
          </td>
        </tr>
        <tr>
          <td>86</td>
          <td>Wyrm (white)</td>
          <td>1,458,200 gp</td>
        </tr>
        <tr className="odd-row">
          <td>87</td>
          <td>Wyrm (brass)</td>
          <td>1,458,200 gp</td>
        </tr>
        <tr>
          <td>88-90</td>
          <td>Epic absorption</td>
          <td>1,500,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>91
          </td>
          <td>Wyrm (copper)</td>
          <td>1,562,600 gp</td>
        </tr>
        <tr>
          <td className="last-row">92
          </td>
          <td className="last-row">Wyrm (black)</td>
          <td className="last-row">1,562,600 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>93
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Wyrm
(bronze)</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1,670,600
gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>94
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Wyrm (green)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1,670,600 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>95
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Wyrm
(blue)</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1,782,200
gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>96
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Wyrm (silver)</td>
          <td style={{
            "verticalAlign": "top"
          }}>1,782,200 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>97
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Wyrm
(gold)</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1,897,400
g9</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>98
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Wyrm (red)</td>
          <td style={{
            "verticalAlign": "top"
          }}> 1,897,400 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>99-100
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Epic
might</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>4,293,432
gp</td>
        </tr>
      </tbody>
    </table>
    <a id="epic-rod-descriptions"></a><h6>EPIC ROD DESCRIPTIONS </h6>
Standard epic rods are described below. 
    <a id="rod-of-besiegement"></a><p><span style={{
        "fontWeight": "bold"
      }}>Besiegement:&nbsp;</span>
This rod functions as a +3 light mace. In addition, it is useful for
besieging fortifications. Whenever the wielder makes a charge attack,
the rod improves to a +6 weapon. Twice per day, the rod can create a
battering ram that lasts for 24 rounds. This ram can strike once per
round, dealing 20 points of damage with each hit. It cannot be used to
target individuals, only fortifications. It can be damaged by normal
means (65 hp, AC 22), and disintegrate or dispel magic destroys it. The
rod also has the following powers: Siege Engine: One heavy catapult,
two light catapults, or three siege towers may be generated with each
use of this power. Each weapon created lasts for 12 hours.&nbsp; The
power can be used three times per day. Ammunition for 20 shots is
included with each weapon created.
      <span style={{
        "fontStyle": "italic"
      }}>Transmute Rock to Mud:</span>
This power can be used three times per day (caster level 24th, save DC
17).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 24th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, Craft Magic Arms and Armor, clenched fist, passwall,&nbsp;
telepathic bond, transmute rock to mud; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 447,745 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 224,025 gp + 14,474
XP. </p>
    <a id="rod-of-epic-absorption"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Absorption:</span> Like
a rod of absorption, this rod draws single-target or ray spells and
spell-like abilities into itself, nullifying the effect and storing the
potential spell levels until the wielder releases the energy in the
form of spells of his or her own. Spells of any level (including those
boosted beyond 9th level by metamagic) can be absorbed, although epic
spells cannot. The rod absorbs a maximum of 150 spell levels and can
thereafter only discharge any remaining potential it might have. The
rod cannot be recharged.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, Empower Spell, Maximize Spell, empowered maximized spell
turning; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
1,500,000 gp. </p>
    <a id="rod-of-epic-cancellation"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Cancellation:</span>
This rod&#8217;s touch drains an item of all magical properties, including
the magical energy in epic magic items (but not most artifacts). The
item touched gets a Will saving throw (DC 26). If a creature is holding
the magic item at the time, then the item can use the holder&#8217;s Will
save bonus in place of its own. In such cases, contact is established
by making a melee touch attack roll. Upon draining three items, the rod
becomes brittle and useless. Drained items can only be restored by
wish, miracle, or epic spells specifically designed to restore lost
power. A rod of epic cancellation can neutralize a normal sphere of
annihilation without itself being cancelled.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 25th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, dispel magic; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
330,000 gp. </p>
    <a id="rod-of-epic-might"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Might:</span> This rod
is similar to a rod of lordly might, although it is far more powerful.
It is larger than its normal counterpart, and it is constructed of
adamantine rather than normal metal. It has six buttons, several
spell-like functions, and several mundane uses, and it can also be used
as a magic weapon of various sorts.
      <p>{`The following spell-like functions of the rod can each be used once per
day.`}</p>
    </p>
    <p>
    </p>
    <ul>
      <li>Dominate Person: Touched foe is recipient of a dominate
person spell,
if the wielder so commands (Will save DC 24). The wielder must choose
to use this power and then succeed with a melee touch attack to
activate the power. If the attack fails, the effect is lost.</li>
      <li>Stun: Upon command, all enemies viewing the rod are
stunned, as per the
power word, stun spell (10-foot maximum range, Will save DC 24).
Invoking this power is a standard action.</li>
      <li>Damage: Upon command, the rod deals 10d8 points of damage
to an
opponent on a successful touch attack and cures the wielder of a like
amount of damage (Will save DC 26). The wielder must choose to use this
power before attacking, as with dominate person.</li>
    </ul>
    <p>{`The following weapon uses of the rod have no limits on their use.`}</p>
    <ul>
      <li>In its normal form, the rod can be used as
a&nbsp;&nbsp;&nbsp; &nbsp;+6 heavy mace.</li>
      <li>When button 1 is pushed, the rod becomes a +3 longsword of
fiery blasting.</li>
      <li>When button 2 is pushed, the rod becomes a +8 battleaxe.</li>
      <li>When button 2 is pushed, the rod becomes a +8 battleaxe.</li>
      <li>When button 3 is pushed, the rod becomes a +10 shortspear
or +10 longspear.</li>
    </ul>
    <p>{`The following mundane uses of the rod also have no limits on their use.`}</p>
    <ul>
      <li>Climbing pole/ladder: When button 4 is pushed, a spike that
can anchor
in granite is extruded from the ball, while the other end sprouts three
sharp hooks. The rod lengthens to anywhere between 5 and 150 feet in a
single round, stopping when button 4 is pushed again. Horizontal bars 3
inches long fold out from the sides, 1 foot apart, in staggered
progression. The rod is firmly held by the spike and hooks and can bear
up to 10,000 pounds. The wielder can retract the pole by pushing button
5.</li>
      <li>The ladder function can be used to force open doors. The
wielder plants
the rod&#8217;s base 30 feet or less from the portal to be forced and in line
with it, then pushes button 4. The force exerted has a Strength bonus
of +24.</li>
      <li>When button 6 is pushed, the rod indicates magnetic north
and gives the
wielder a knowledge of his or her approximate depth beneath the surface
or height above it.</li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 30th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, Craft Magic Arms and Armor, Craft Epic Magic Arms and Armor,
cure critical wounds, inflict critical wounds, bull&#8217;s strength,
fireball, dominate person, power word stun; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 4,293,432 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 2,151,372 gp +
52,841 XP. </p>
    <a id="rod-of-epic-negation"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Negation:</span> This
rod negates the spell or spell-like function or functions of magic
items, including epic magic items (but not artifacts). The wielder
points the rod at the magic item, and a beam shoots forth to touch the
target device, attacking as a ray (a ranged touch attack). The ray
negates any currently active item function and has a 75% chance to
negate any other spell or spell-like functions of that device,
regardless of the level or power of the functions, for 2d4 rounds. To
negate instantaneous effects, the rod wielder needs to have readied an
action. The target item gets no saving throw or means to resist this
effect. The rod can function three times per day.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 24th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, dispel magic, limited wish or miracle; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 446,000 gp. </p>
    <a id="rod-of-epic-rulership"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Rulership:</span> This
rod appears to be a royal scepter worth at least 25,000 gp in materials
and work-manship alone. The wielder can command the obedience and
fealty of creatures within 360 feet when he or she activates the device
(a standard action). Creatures totaling 900 Hit Dice can be ruled, but
creatures with Intelligence scores of 17 or higher are entitled to a
Will saving throw (DC 29) to negate the effect. Ruled creatures obey
the wielder as if he or she were their absolute sovereign. Still, if
the wielder gives a command that is contrary to the nature of the
creatures commanded, the magic is broken. The rod can be used for 1,500
total minutes before crumbling to dust. This duration need not be
continuous.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 25th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, Improved Heighten Spell, improved heightened mass charm; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 575,000 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 300,000 gp + 15,500
XP. </p>
    <a id="rod-of-epic-spellcaster"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Spellcaster:</span> This
magnificent adamantine rod grants its wielder a +10 insight bonus on
Spellcraft checks made to cast epic spells for as long as he or she
holds or carries the rod.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
245,400 gp. </p>
    <a id="rod-of-epic-splendor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Epic Splendor:</span> This
rod grants its wielder a +8 enhancement bonus to Charisma for as long
as he or she holds or carries the item. Three times per day, the rod
creates and garbs him or her in clothing of the finest fabrics, plus
adornments of fur and jewels. Apparel created by the rod can remain in
existence up to 24 hours. The value of the garb ranges from 70,000 to
100,000 gp (1d4+6 x10,000 gp)-10,000 gp for the fabric, 50,000 gp for
the furs, and the rest for jewel trim (maximum of 40 gems, maximum
value 1,000 gp each). In addition, the rod can, once per week, create a
palatial mansion in any floor plan the user desires. The mansion is
palatial, able to accommodate up to 250 people, housing them in private
chambers and serving them fine banquets. The mansion lasts for three
days, after which time it, and everything originally in it (including
items removed from the mansion), disappear.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, fabricate, mage&#8217;s magnificent mansion; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 297,400 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 191,200 gp + 12,124
XP. </p>
    <a id="rod-of-excellent-magic"></a><p><span style={{
        "fontWeight": "bold"
      }}>Excellent Magic:</span> Once
per day when casting a nonepic or epic spell that has an experience
point component, the rod supplies up to 2,000 XP, not the caster. If
more experience points are required to cast the spell, the caster
provides them. As a special use of the rod, the caster can substitute
the power inherent in the rod for the experience point development cost
of an epic spell. Doing so drains all the power from the rod, rendering
it useless.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
650,000 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span>
325,000 gp + 26,000 XP. </p>
    <a id="rod-of-fortification"></a><p><span style={{
        "fontWeight": "bold"
      }}>Fortification:</span> This
rod functions as a +3 light mace. In addition, it is useful for the
construction and defense of fortifications. Whenever the wielder is
benefiting from cover, the rod improves that to nine-tenths cover (+10
cover AC bonus and +4 cover Reflex save bonus; failure on the save
results in half damage and success results in no damage). Three times
per day, the rod can create food and water, as per the cleric spell,
for twenty-four people. The rod also has the following powers: Fortify:
Four times per day, a stone wall can be created that is 12 inches
thick, 10 feet high, and 30 feet long. This wall has a parapet and
battlements across the top. (Alternatively, the power can be used to
mend a broken existing wall. The gap to be filled can be no more than
300 square feet in area.) In addition, once per day, a great door of
iron can be created that is set into one of the newly created walls.
This door, 4 inches thick, may be a double door, a drawbridge, or a
portcullis, as chosen by the wielder. Siege Engine: One heavy catapult,
two light catapults, or three ballistae may be generated with each use
of this power. The power can be used four times per day. Ammunition for
20 shots is included with each weapon created.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 24th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, Craft Magic Arms and Armor, inter-posing hand, create food
and water, major creation, wall of iron, wall of stone; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 465,665 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 231,985 gp + 14,634
XP. </p>
    <a id="rod-of-invulnerability"></a><p><span style={{
        "fontWeight": "bold"
      }}>Invulnerability:</span> When
held, this rod grants its wielder the following powers.
    </p>
    <ul>
      <li>+5 enhancement bonus to natural armor.</li>
      <li>+5 resistance bonus on saving throws.</li>
      <li>Damage reduction 15/adamantine.</li>
      <li>Immunity to critical hits.</li>
      <li>Spell resistance 32.</li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, iron body, resistance, spell resistance; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 600,000 gp. </p>
    <a id="rod-of-nightmares"></a><p><span style={{
        "fontWeight": "bold"
      }}>Nightmares:</span> Anyone who
comes within 20 feet of the wielder of this rod feels a grave sense of
unease. Each person so affected must make a Will save (DC 17) or suffer
the effects of a nightmare spell the next time he or she falls asleep.
The wielder is immune to this effect. Three times per day, the wielder
can utter a command word that causes the rod to emit a horrid, inhuman
scream. Up to twenty of the closest creatures within a 30-foot radius
who hear this terrible wail believe that their worst nightmares have
become reality and suffer the effects of a wail of the banshee spell
(DC 23).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, nightmare, permanency, wail of the banshee; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 284,000 gp. </p>
    <a id="rod-of-paradise"></a><p><span style={{
        "fontWeight": "bold"
      }}>Paradise:</span> This item
creates a nondimensional space, similar in effect to a rod of security.
However, the rod&#8217;s possessor and up to 999 other creatures can stay in
complete safety for up to 1,000 days divided by the number of creatures
affected. Natural healing takes place at five times the normal pace.
The rod functions like its nonepic counterpart in all other ways.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 24th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, Improved Heighten Spell, improved heightened gate; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 610,000 gp. </p>
    <a id="rod-of-the-path"></a><p><span style={{
        "fontWeight": "bold"
      }}>The Path:</span> This rod
serves as an aid to trailblazing and travel. It grants the wielder a
+30 enhancement bonus on Wilderness Lore checks for tracking and Intuit
Direction checks. The handle of the rod is hollow, functioning like a
telescope. When the wielder peers through it, the limits of vision are
three times normal (and spotting distances for encounters are tripled).
In addition, the telescoping handle enables the wielder to view things
as though affected by a true seeing spell. The rod also has the
following powers. Map: Three times per day a section of the rod unrolls
like a scroll from a tube, revealing a map of the surrounding area,
centered on the location of the rod. The area shown on the map covers
an area as small as 50 feet in radius to as large as 24 miles in
radius, zooming in or out with a set of command words. The map reveals
natural topography and all types of structures (even hidden ones), but
it will not show the location of creatures.
      <span style={{
        "fontStyle": "italic"
      }}>Passage:</span> Three times per
day, this power allows the wielder and up to five others in a 20-foot
radius to move unhindered through natural plant growth or bodies of
water (as per the freedom of movement spell).
      <span style={{
        "fontStyle": "italic"
      }}>Bridge:</span> Once per day,
this power allows the user to create a 5-foot-wide, 40-foot-long stone
cause-way across chasms and canyons. The bridge created lasts for 1
hour.
      <span style={{
        "fontStyle": "italic"
      }}>Pass without Trace:</span> Once
per day, this power can be used on the wielder and twenty others, for
21 minutes. It is otherwise as the spell of the same name (caster level
24th).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, find the path, freedom of movement, pass without trace, wall
of stone; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
306,870 gp. </p>
    <a id="rod-of-restless-death"></a><p><span style={{
        "fontStyle": "bold"
      }}>Restless Death:</span> The
holder of this rod can rebuke or control undead as if he or she were
four levels higher. (The rod doesn&#8217;t grant the ability to rebuke or
control undead if the holder doesn&#8217;t already have it.) In addition, the
wielder may speak a command word to cast animate dead. Any skeletons or
zombies animated by the rod&#8217;s power are automatically controlled by the
rod, up to the rod&#8217;s maximum limit of 42 HD, and they follow the orders
of the rod&#8217;s wielder. These undead don&#8217;t count toward the wielder&#8217;s
limit of controlled undead. Finally, the wielder can speak a second
command word to cast slay living (heightened to 10th level; DC 25).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Rod, Craft
Epic Rod, animate dead, control undead, slay living; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 625,000 gp. </p>
    <a id="rod-of-wyrm"></a><p><span style={{
        "fontWeight": "bold"
      }}>Wyrm:</span> Any of the
various rods in this series functions as a +5 quarterstaff. Upon
casting the rod to the ground (a standard action) and uttering a
command word, the rod grows into a specific type of dragon (depending
on the specific type of rod) by the end of the round. The dragon
created is a wyrm and obeys the commands of the owner. The dragon
returns to rod form (a full-round action) whenever the wielder desires,
or whenever it moves farther than 500 feet from the owner. If the
dragon form is slain, it returns to rod form and cannot be activated
again for three days. A wyrm rod only functions if the possessor is of
the same alignment as the dragon type.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 34th
(white or brass), 35th (black or copper), 36th (green or bronze), 37th
(blue or silver), 38th (red or gold); <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span>
Craft Rod, Craft Epic Rod, Craft Magic Arms and Armor, Craft Epic Magic
Arms and Armor, Improved Heighten Spell, improved heightened polymorph,
creator must be same alignment as dragon type; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 1,458,200 gp (brass
or white), 1,562,600 gp (black or copper), 1,670,600 gp (bronze or
green), 1,782,200 gp (blue or silver), 1,897,400 gp (gold or red); <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 729,400 gp + 24,576
XP (brass or white), 781,600 gp + 25,620 XP (black or copper), 835,600
gp + 26,700 XP (bronze or green), 891,400 gp + 27,816 XP (blue or
silver), 949,000 gp + 28,968 XP (gold or red).
    </p>
    <a id="epic-scrolls"></a><h3>SCROLLS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
To generate an epic scroll randomly, first roll on Table: Scroll Types
to determine whether the spells are arcane or divine. An epic scroll
contains 1d8 spells. For each spell, roll on Table: Epic Scroll Spell
Levels to determine its level and then on the appropriate section of
Table: Actual Spell Levels to determine the spell&#8217;s actual level and
total metamagic level adjustments. To determine the specific metamagic
level adjustments, roll on the appropriate section of Table: Metamagic
Level Adjustment to Spells. To determine the spell, use the appropriate
sections of the nonepic scroll tables. 
    <a id="table-scroll-types"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Scroll Types </span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "300px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>d% roll</th>
          <th style={{
            "width": "75%"
          }}>Type</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;70</td>
          <td>Arcane</td>
        </tr>
        <tr>
          <td className="last-row">71&#8211;100</td>
          <td className="last-row">Divine</td>
        </tr>
      </tbody>
    </table>
    <a id="table-epic-scroll-spell-levels"></a><span style={{
      "fontWeight": "bold"
    }}>Table: Epic Scroll Spell Levels</span>
    <table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "10%"
          }}>d%
          </th>
          <th style={{
            "width": "30%"
          }}>Spell Level 
          </th>
          <th style={{
            "width": "20%"
          }}>Spell&#8217;s Caster Level
          </th>
          <th style={{
            "width": "20%"
          }}>Market Price<sup>1</sup>
          </th>
          <th style={{
            "width": "20%"
          }}>XP Cost
          </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>01-26
          </td>
          <td style={{
            "width": "30%"
          }}>10th
          </td>
          <td style={{
            "width": "20%"
          }}>21st
          </td>
          <td style={{
            "width": "20%"
          }}>5,250 gp
          </td>
          <td style={{
            "width": "20%"
          }}>1,210 XP
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>27-46
          </td>
          <td style={{
            "width": "30%"
          }}>11th
          </td>
          <td style={{
            "width": "20%"
          }}>22nd
          </td>
          <td style={{
            "width": "20%"
          }}>6,050 gp
          </td>
          <td style={{
            "width": "20%"
          }}>1,242 XP
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>47-61
          </td>
          <td style={{
            "width": "30%"
          }}>12th
          </td>
          <td style={{
            "width": "20%"
          }}>23rd
          </td>
          <td style={{
            "width": "20%"
          }}>6,900 gp
          </td>
          <td style={{
            "width": "20%"
          }}>1,276 XP
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>62-71
          </td>
          <td style={{
            "width": "30%"
          }}>13th
          </td>
          <td style={{
            "width": "20%"
          }}>24th
          </td>
          <td style={{
            "width": "20%"
          }}>7,800 gp
          </td>
          <td style={{
            "width": "20%"
          }}>1,312 XP
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>72-79
          </td>
          <td style={{
            "width": "30%"
          }}>14th
          </td>
          <td style={{
            "width": "20%"
          }}>25th
          </td>
          <td style={{
            "width": "20%"
          }}>8,750 gp
          </td>
          <td style={{
            "width": "20%"
          }}>1,350 XP
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>80-85
          </td>
          <td style={{
            "width": "30%"
          }}>15th
          </td>
          <td style={{
            "width": "20%"
          }}>26th
          </td>
          <td style={{
            "width": "20%"
          }}>9,750 gp
          </td>
          <td style={{
            "width": "20%"
          }}>1,390 XP
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>86-90
          </td>
          <td style={{
            "width": "30%"
          }}>16th
          </td>
          <td style={{
            "width": "20%"
          }}>27th
          </td>
          <td style={{
            "width": "20%"
          }}>10,800 gp
          </td>
          <td style={{
            "width": "20%"
          }}>1,432 XP
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>91-94
          </td>
          <td style={{
            "width": "30%"
          }}>17th
          </td>
          <td style={{
            "width": "20%"
          }}>28th
          </td>
          <td style={{
            "width": "20%"
          }}>11,900 gp
          </td>
          <td style={{
            "width": "20%"
          }}>1,476 XP
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>95-97
          </td>
          <td style={{
            "width": "30%"
          }}>18th
          </td>
          <td style={{
            "width": "20%"
          }}>29th
          </td>
          <td style={{
            "width": "20%"
          }}>13,050 gp
          </td>
          <td style={{
            "width": "20%"
          }}>1,522 XP
          </td>
        </tr>
        <tr>
          <td className="last-row" style={{
            "width": "10%"
          }}>98-99
          </td>
          <td className="last-row" style={{
            "width": "30%"
          }}>19th
          </td>
          <td className="last-row" style={{
            "width": "20%"
          }}>30th
          </td>
          <td className="last-row" style={{
            "width": "20%"
          }}>14,250 gp
          </td>
          <td className="last-row" style={{
            "width": "20%"
          }}>1,570 XP
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "10%"
          }}>100
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "30%"
          }}>Roll
again and add +10<sup>2</sup> 
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "20%"
          }}>Spell
level +11
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "20%"
          }}>Varies
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15px"
          }}>Varies
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="5">1 <span style={{
              "fontStyle": "italic"
            }}>Market price does not include price of
material components or XP costs for spell.</span>
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="5">2 <span style={{
              "fontStyle": "italic"
            }}>This result is cumulative if rolled
multiple times</span></td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr align="center">
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="3"><a id="table-actual-spell-level"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Actual Spell Level </span></td>
        </tr>
        <tr>
          <td style={{
            "width": "47%",
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}></span><span style={{
              "fontWeight": "bold"
            }}>10th-Level
Spell</span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Spell Level and Metamagic Level
Adjustment</th>
                </tr>
                <tr className="odd-row">
                  <td>01-03
                  </td>
                  <td>1st-level spell plus metamagic (9)</td>
                </tr>
                <tr>
                  <td>04-08</td>
                  <td>2nd-level spell plus metamagic (8)&nbsp; 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>09-15</td>
                  <td>3rd-level spell plus metamagic (7) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>16-24</td>
                  <td>4th-level spell plus metamagic (6) </td>
                </tr>
                <tr className="odd-row">
                  <td>25- 35</td>
                  <td>5th-level spell plus metamagic (5) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>36-48 </td>
                  <td>6th-level spell plus metamagic (4) </td>
                </tr>
                <tr className="odd-row">
                  <td>49-63</td>
                  <td>7th-level spell plus metamagic (3)&nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>64-80&nbsp;&nbsp; 
                  </td>
                  <td>8th-level spell plus metamagic (2)
&nbsp;&nbsp;&nbsp; </td>
                </tr>
                <tr className="odd-row">
                  <td>81-99</td>
                  <td>9th-level spell plus metamagic (1)&nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>100</td>
                  <td>Any</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>11th-Level Spell</span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Spell Level and Metamagic Level
Adjustment</th>
                </tr>
                <tr className="odd-row">
                  <td>01-03
                  </td>
                  <td>1st-level spell plus metamagic (10)</td>
                </tr>
                <tr>
                  <td>04-08</td>
                  <td>2nd-level spell plus metamagic (9)&nbsp; 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>09-15</td>
                  <td>3rd-level spell plus metamagic (8) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>16-24</td>
                  <td>4th-level spell plus metamagic (7) </td>
                </tr>
                <tr className="odd-row">
                  <td>25- 35</td>
                  <td>5th-level spell plus metamagic (6) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>36-48 </td>
                  <td>6th-level spell plus metamagic (5) </td>
                </tr>
                <tr className="odd-row">
                  <td>49-63</td>
                  <td>7th-level spell plus metamagic (4)&nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>64-80&nbsp;&nbsp; 
                  </td>
                  <td>8th-level spell plus metamagic (3)
&nbsp;&nbsp;&nbsp; </td>
                </tr>
                <tr className="odd-row">
                  <td>81-99</td>
                  <td>9th-level spell plus metamagic (2)&nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>100</td>
                  <td>Any</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>12th-Level Spell</span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Spell Level and Metamagic Level
Adjustment</th>
                </tr>
                <tr className="odd-row">
                  <td>01-05
                  </td>
                  <td>2nd-level spell plus metamagic (10)</td>
                </tr>
                <tr>
                  <td>06-12
                  </td>
                  <td>3rd-level spell plus metamagic (9)&nbsp; 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>13-21
                  </td>
                  <td>4th-level spell plus metamagic (8) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>22-32
                  </td>
                  <td>5th-level spell plus metamagic (7) </td>
                </tr>
                <tr className="odd-row">
                  <td>33-45
                  </td>
                  <td>6th-level spell plus metamagic (6) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>46-60
                  </td>
                  <td>7th-level spell plus metamagic (5) </td>
                </tr>
                <tr className="odd-row">
                  <td>61-77
                  </td>
                  <td>8th-level spell plus metamagic (4)&nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>78-99
                  </td>
                  <td>9th-level spell plus metamagic (3)
&nbsp;&nbsp;&nbsp; </td>
                </tr>
                <tr className="odd-row">
                  <td>100
                  </td>
                  <td>Any </td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>13th-Level Spell
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Spell Level and Metamagic Level
Adjustment</th>
                </tr>
                <tr className="odd-row">
                  <td>01-08
                  </td>
                  <td>3rd-level spell plus metamagic (10)</td>
                </tr>
                <tr>
                  <td>09-18
                  </td>
                  <td>4th-level spell plus metamagic (9)&nbsp; 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>19-30
                  </td>
                  <td>5th-level spell plus metamagic (8) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>31-44
                  </td>
                  <td>6th-level spell plus metamagic (7) </td>
                </tr>
                <tr className="odd-row">
                  <td>45-60
                  </td>
                  <td>7th-level spell plus metamagic (6) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>61-78
                  </td>
                  <td>8th-level spell plus metamagic (5) </td>
                </tr>
                <tr className="odd-row">
                  <td>79-99
                  </td>
                  <td>9th-level spell plus metamagic (4)&nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>100</td>
                  <td>Any</td>
                </tr>
              </tbody>
            </table>
          </td>
          <th style={{
            "width": "6%"
          }}> 
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "47px"
          }}><span style={{
              "fontWeight": "bold"
            }}></span><span style={{
              "fontWeight": "bold"
            }}>14th-Level
Spell
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Spell Level and Metamagic Level
Adjustment</th>
                </tr>
                <tr className="odd-row">
                  <td>01-11
                  </td>
                  <td>4th-level spell plus metamagic (10)</td>
                </tr>
                <tr>
                  <td>12-24
                  </td>
                  <td>5th-level spell plus metamagic (9)&nbsp; 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>25-39
                  </td>
                  <td>6th-level spell plus metamagic (8) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>40-56
                  </td>
                  <td>7th-level spell plus metamagic (7) </td>
                </tr>
                <tr className="odd-row">
                  <td>57-75
                  </td>
                  <td>8th-level spell plus metamagic (6) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>76-99
                  </td>
                  <td>9th-level spell plus metamagic (5) </td>
                </tr>
                <tr className="odd-row">
                  <td>100</td>
                  <td>Any</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>15th-Level Spell
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Spell Level and Metamagic Level
Adjustment</th>
                </tr>
                <tr className="odd-row">
                  <td>01-15
                  </td>
                  <td>5th-level spell plus metamagic (10)</td>
                </tr>
                <tr>
                  <td>16-33
                  </td>
                  <td>6th-level spell plus metamagic (9)&nbsp; 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>34-53
                  </td>
                  <td>7th-level spell plus metamagic (8) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>54-75
                  </td>
                  <td>8th-level spell plus metamagic (7) </td>
                </tr>
                <tr className="odd-row">
                  <td>76-99
                  </td>
                  <td>9th-level spell plus metamagic (6) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>100</td>
                  <td>Any</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>16th-Level Spell
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Spell Level and Metamagic Level
Adjustment</th>
                </tr>
                <tr className="odd-row">
                  <td>01-18
                  </td>
                  <td>6th-level spell plus metamagic (10)</td>
                </tr>
                <tr>
                  <td>19-41
                  </td>
                  <td>7th-level spell plus metamagic (9)&nbsp; 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>42-68</td>
                  <td>8th-level spell plus metamagic (8) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>69-99
                  </td>
                  <td>9th-level spell plus metamagic (7) </td>
                </tr>
                <tr className="odd-row">
                  <td>100</td>
                  <td>Any</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>17th-Level Spell
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Spell Level and Metamagic Level
Adjustment</th>
                </tr>
                <tr className="odd-row">
                  <td>01-25
                  </td>
                  <td>7th-level spell plus metamagic (10)</td>
                </tr>
                <tr>
                  <td>26-60
                  </td>
                  <td>8th-level spell plus metamagic (9)&nbsp; 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>61-99
                  </td>
                  <td>9th-level spell plus metamagic (8) &nbsp; 
                  </td>
                </tr>
                <tr>
                  <td>100</td>
                  <td>Any</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>18th-Level Spell
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Spell Level and Metamagic Level
Adjustment</th>
                </tr>
                <tr className="odd-row">
                  <td>01-41
                  </td>
                  <td>8th-level spell plus metamagic (10)</td>
                </tr>
                <tr>
                  <td>42-99
                  </td>
                  <td>9th-level spell plus metamagic (9)&nbsp; 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>100</td>
                  <td>Any</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>19th-Level Spell
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Spell Level and Metamagic Level
Adjustment</th>
                </tr>
                <tr className="odd-row">
                  <td>01-99
                  </td>
                  <td>9th-level spell plus metamagic (10)</td>
                </tr>
                <tr>
                  <td>100</td>
                  <td>Any</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr align="center">
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="3"><a id="table-metamagic-level-adjustment-to-spells"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Metamagic Level Adjustment to Spells</span></td>
        </tr>
        <tr>
          <td style={{
            "width": "47%",
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}></span><span style={{
              "fontWeight": "bold"
            }}>Metamagic
(1)</span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Metamagic Effects</th>
                </tr>
                <tr className="odd-row">
                  <td>01-10
                  </td>
                  <td>Enlarge Spell</td>
                </tr>
                <tr>
                  <td>21-40</td>
                  <td>Extend Spell</td>
                </tr>
                <tr className="odd-row">
                  <td>41-80</td>
                  <td>Heighten Spell (+1 level)</td>
                </tr>
                <tr>
                  <td>81-100</td>
                  <td>Silent Spell</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>Metamagic (2)
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Metamagic Effects</th>
                </tr>
                <tr className="odd-row">
                  <td>01-25
                  </td>
                  <td>Empower Spell</td>
                </tr>
                <tr>
                  <td>26-50</td>
                  <td>Heighten Spell (+2 levels)</td>
                </tr>
                <tr className="odd-row">
                  <td>51-55</td>
                  <td>Roll on Metamagic (1) and add Enlarge Spell</td>
                </tr>
                <tr>
                  <td>56-65</td>
                  <td>Roll on Metamagic (1) and add Extend Spell</td>
                </tr>
                <tr className="odd-row">
                  <td>66-90</td>
                  <td>Roll on Metamagic (1) and add Heighten Spell (+1
level)</td>
                </tr>
                <tr>
                  <td>91-100</td>
                  <td>Roll on Metamagic (1) and add Silent Spell</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>Metamagic (3)
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Metamagic Effects</th>
                </tr>
                <tr className="odd-row">
                  <td>01-25
                  </td>
                  <td>Heighten Spell (+3 levels)</td>
                </tr>
                <tr>
                  <td>26-50</td>
                  <td>Maximize Spell</td>
                </tr>
                <tr className="odd-row">
                  <td>51-65</td>
                  <td>Roll on Metamagic (1) and add Empower Spell</td>
                </tr>
                <tr>
                  <td>66-70</td>
                  <td>Roll on Metamagic (2) and add Enlarge Spell</td>
                </tr>
                <tr className="odd-row">
                  <td>71-75</td>
                  <td>Roll on Metamagic (2) and add Extend Spell</td>
                </tr>
                <tr>
                  <td>76-95</td>
                  <td>Roll on Metamagic (2) and add Heighten Spell (+1
level)</td>
                </tr>
                <tr className="odd-row">
                  <td>96-100</td>
                  <td>Roll on Metamagic (2) and add Silent Spell</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>Metamagic (4)
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Metamagic Effects</th>
                </tr>
                <tr className="odd-row">
                  <td>01-15
                  </td>
                  <td>Enhance Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>16-40</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Heighten Spell (+4
levels)</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>41-55</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Quicken
Spell </td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>56-65</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (1) and add Maximize Spell </td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>66-75</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (2) and add Empower Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>76-80</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (3) and add Enlarge Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>81-85</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (3) and add Extend Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>86-95</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (3) and add Heighten Spell (+1 level)</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>96-100</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (3) and add Silent Spell</td>
                </tr>
              </tbody>
            </table>
            <p>{`Metamagic (5)`}</p>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Metamagic Effects</th>
                </tr>
                <tr className="odd-row">
                  <td>01-30
                  </td>
                  <td>Heighten Spell (+5 levels)</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>31-40</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (1) and add Enhance Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>41-55</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (1) and add Quicken Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>56-65</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (2) and add Maximize Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>66-75</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (3) and add Empower Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>76-80</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (4) and add Enlarge Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>81-85</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (4) and add Extend Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)",
                    "color": "rgb(0, 0, 0)"
                  }}>86-95</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)",
                    "color": "rgb(0, 0, 0)"
                  }}>Roll
on Metamagic (4) and add Heighten Spell (+1 level)</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>96-100</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (4) and add Silent Spell</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>Metamagic (6)
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Metamagic Effects</th>
                </tr>
                <tr className="odd-row">
                  <td>01-30
                  </td>
                  <td>Heighten Spell (+6 levels)</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>31-40</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (2) and add Enhance Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>41-55</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (2) and add Quicken Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>56-65</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (3) and add Maximize Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>66-75</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (4) and add Empower Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>76-80</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (5) and add Enlarge Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>81-85</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (5) and add Extend Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)",
                    "color": "rgb(0, 0, 0)"
                  }}>86-95</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)",
                    "color": "rgb(0, 0, 0)"
                  }}>Roll
on Metamagic (5) and add Heighten Spell (+1 level)</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>96-100</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (5) and add Silent Spell</td>
                </tr>
              </tbody>
            </table>
          </td>
          <th style={{
            "width": "6%"
          }}> 
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "47px"
          }}><span style={{
              "fontWeight": "bold"
            }}></span><span style={{
              "fontWeight": "bold"
            }}>Metamagic
(7)
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Metamagic Effects</th>
                </tr>
                <tr className="odd-row">
                  <td>01-25
                  </td>
                  <td>Heighten Spell (+7 levels)</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>26-45
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Intensify Spell
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>46-55</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (3) and add Enhance Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>56-65</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (3) and add Quicken Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>66-75</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (4) and add Maximize Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>75-80</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (5) and add Empower Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>81-85</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (6) and add Enlarge Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>86-90</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (6) and add Extend Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "color": "rgb(0, 0, 0)",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>91-95</td>
                  <td style={{
                    "color": "rgb(0, 0, 0)",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (6) and add Heighten Spell (+1 level)</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>96-100</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (6) and add Silent Spell</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>Metamagic (8)
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Metamagic Effects</th>
                </tr>
                <tr className="odd-row">
                  <td>01-30
                  </td>
                  <td>Heighten Spell (+8 levels)</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>31-45
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Roll on Metamagic
(1) and add Intensify Spell 
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>46-55</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (4) and add Enhance Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>56-65</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (4) and add Quicken Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>66-75</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (5) and add Maximize Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>75-80</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (6) and add Empower Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>81-85</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (7) and add Enlarge Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>86-90</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (7) and add Extend Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "color": "rgb(0, 0, 0)",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>91-95</td>
                  <td style={{
                    "color": "rgb(0, 0, 0)",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (7) and add Heighten Spell (+1 level)</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>96-100</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (7) and add Silent Spell</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>Metamagic (9)
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Metamagic Effects</th>
                </tr>
                <tr className="odd-row">
                  <td>01-30
                  </td>
                  <td>Heighten Spell (+9 levels)</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>31-45
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Roll on Metamagic
(2) and add Intensify Spell 
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>46-55</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Msetamagic (5) and add Enhance Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>56-65</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (5) and add Quicken Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>66-75</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (6) and add Maximize Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>75-80</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (7) and add Empower Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>81-85</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (8) and add Enlarge Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>86-90</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (8) and add Extend Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "color": "rgb(0, 0, 0)",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>91-95</td>
                  <td style={{
                    "color": "rgb(0, 0, 0)",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (8) and add Heighten Spell (+1 level)</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>96-100</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (8) and add Silent Spell</td>
                </tr>
              </tbody>
            </table>
            <span style={{
              "fontWeight": "bold"
            }}>Metamagic (10)
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Metamagic Effects</th>
                </tr>
                <tr className="odd-row">
                  <td>01-30
                  </td>
                  <td>Heighten Spell (+10 levels)</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>31-45
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Roll on Metamagic
(3) and add Intensify Spell 
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>46-55</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (6) and add Enhance Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>56-65</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (6) and add Quicken Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>66-75</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (7) and add Maximize Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>75-80</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (8) and add Empower Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>81-85</td>
                  <td style={{
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (9) and add Enlarge Spell</td>
                </tr>
                <tr className="odd-row">
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>86-90</td>
                  <td style={{
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (9) and add Extend Spell</td>
                </tr>
                <tr>
                  <td style={{
                    "color": "rgb(0, 0, 0)",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>91-95</td>
                  <td style={{
                    "color": "rgb(0, 0, 0)",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Roll
on Metamagic (9) and add Heighten Spell (+1 level)</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>96-100</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(255, 255, 255)"
                  }}>Roll
on Metamagic (9) and add Silent Spell</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-staffs"></a><h3>STAFFS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
Staffs have 50 charges when created, and they can&#8217;t be recharged. 
    <p>Staffs use the wielder&#8217;s ability score and relevant feats to
set the DC for saves against their spells in such cases where those
values would be higher than the listed values for the staffs.
    </p>
    <a id="table-epic-staffs"></a><table style={{
      "width": "300px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>d%</th>
          <th style={{
            "width": "35%"
          }}>Staff</th>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Market
Price</td>
        </tr>
        <tr className="odd-row">
          <td>01-09</td>
          <td>Spheres
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>228,375 gp</td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>10-18</td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Mighty
force</td>
          <td style={{
            "verticalAlign": "top"
          }}>265,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>19-27</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Walls</td>
          <td style={{
            "verticalAlign": "top"
          }}>275,625 gp</td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>28-36</td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Winter</td>
          <td style={{
            "verticalAlign": "top"
          }}>292,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>37-45</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Prism</td>
          <td style={{
            "verticalAlign": "top"
          }}>326,812 gp</td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>46-54</td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Rapid
barrage</td>
          <td style={{
            "verticalAlign": "top"
          }}>417,750 gp</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>55-63</td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Planar
might</td>
          <td style={{
            "verticalAlign": "top"
          }}>460,000 gp</td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)",
            "color": "rgb(0, 0, 0)"
          }}>64-71</td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)",
            "color": "rgb(0, 0, 0)"
          }}>Domination</td>
          <td style={{
            "verticalAlign": "top"
          }}>464,400 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>72-79</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Fiery
power</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>500,000
gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>80-87</td>
          <td style={{
            "verticalAlign": "top"
          }}>Nature&#8217;s fury</td>
          <td style={{
            "verticalAlign": "top"
          }}>500,000 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>88-95</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Hierophants</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>501,187
gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>96-98</td>
          <td style={{
            "verticalAlign": "top"
          }}>Cosmos</td>
          <td style={{
            "verticalAlign": "top"
          }}>683,487 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>99-100</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Necromancy</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1,505,312
gp</td>
        </tr>
      </tbody>
    </table>
    <a id="epic-staff-descriptions"></a><h5>Epic Staff Descriptions </h5>
Standard epic staffs are described below. 
    <a id="staff-of-cosmos"></a><p><span style={{
        "fontWeight": "bold"
      }}>Cosmos:</span>&nbsp; This
staff allows use of the following spells.
    </p>
    <ul>
      <li>Chain lightning (intensified, 1 charge, DC 29)</li>
      <li>Meteor swarm (intensified, 1 charge, DC 34)</li>
      <li>Sunburst (intensified, 1 charge, DC 32)
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 27th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Intensify Spell, chain lightning, meteor swarm, sunburst; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 683,437 gp. </p>
    <a id="staff-of-domination"></a><p><span style={{
        "fontWeight": "bold"
      }}>Domination:</span> This staff
allows use of the following heightened spells.
    </p>
    <ul>
      <li>Dominate monster (1 charge, DC 33)</li>
      <li>Demand (1 charge, DC 32)</li>
      <li>Mass charm monster (1 charge, DC 32)</li>
      <li>Geas (heightened, 1 charge, DC 33)
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Heighten Spell, demand, dominate monster, geas, mass charm
monster; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
464,400 gp. </p>
    <a id="staff-of-fiery-power"></a><p><span style={{
        "fontWeight": "bold"
      }}>Fiery Power:</span> This +5
flaming quarterstaff grants its wielder fire resistance 30 whenever
held. In addition, it has the following powers.
    </p>
    <ul>
      <li>Wall of fire (extended, 1 charge, DC 18)</li>
      <li>Delayed blast fireball (intensified to 240 points of
damage, 2 charges, DC 22)</li>
      <li>Meteor swarm (heightened to 12th, 2 charges, DC 30)</li>
      <li>Summon monster IX (extended, 2 charges, elder fire
elemental only) A staff of fiery power can be broken in a retributive
strike. The breaking of the staff must be purposeful and declared by
the wielder. All charges currently in the staff are instantly released
in a 30-foot- radius globe. All within 10 feet of the broken staff take
points of damage equal to eight times the number of charges in the
staff, those between 11 feet and 20 feet away take six times the number
of charges in damage, and those 21 feet to 30 feet distant take four
times the number of charges in damage. Successful Reflex saving throws
(DC 17) reduce the damage sustained by half. The character breaking the
staff has a 50% chance of traveling to another plane of existence; if
he or she does not, the explosive release of spell energy destroys him
or her. After all charges are used up from the staff, it remains a +5
quarterstaff with no special abilities. (Once empty of charges, it
cannot be broken in a retributive strike.)
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 25th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Extend Spell, Heighten Spell, Improved Heighten Spell,
Intensify Spell, Spell Focus (Evocation), continual flame, delayed
blast fireball, meteor swarm, protection from energy, summon monster
IX, wall of fire; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
500,000 gp. </p>
    <a id="staff-of-the-hierophants"></a><p><span style={{
        "fontWeight": "bold"
      }}>The Hierophants:</span> This
staff allows use of the following spells.
    </p>
    <ul>
      <li>Creeping doom (enlarged, extended, 1 charge, DC 23)</li>
      <li>Command plants (enlarged, extended, 1 charge, DC 25)</li>
      <li>Elemental swarm (intensified, 2 charges, DC 34)</li>
      <li>Shambler (intensified, 2 charges, DC 34)
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 27th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Enlarge Spell, Extend Spell, Intensify Spell, creeping
doom, command plants, elemental swarm, shambler; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 501,187 gp. </p>
    <a id="staff-of-mighty-force"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mighty Force:</span> This
staff has three powers.
    </p>
    <ul>
      <li>Shield (quickened, 1 charge, may be activated once per
round)</li>
      <li>Forcecage (1 charge)</li>
      <li>Crushing hand (1 charge)
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Quicken Spell, crushing hand, forcecage, shield; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 265,000 gp.</p>
    <a id="staff-of-natures-fury"></a><p><span style={{
        "fontWeight": "bold"
      }}>Nature&#8217;s Fury:</span> This +5
aberration bane quarterstaff allows use of the following spells.
    </p>
    <ul>
      <li>Earthquake (1 charge)</li>
      <li>Whirlwind (heightened to 10th level, 1 charge, DC 25)</li>
      <li>Fire storm (heightened to 10th level, 1 charge, DC 25)
      </li>
    </ul>
    <p>After all charges are used up from a staff of nature&#8217;s fury,
it remains a +5 quarterstaff with no special abilities.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Heighten Spell, Improved Heighten Spell, earthquake, fire
storm, summon monster I (or summon nature&#8217;s ally I), whirlwind; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 500,000 gp. </p>
    <a id="staff-of-necromancy"></a><p><span style={{
        "fontWeight": "bold"
      }}>Necromancy:</span> This staff
allows use of the following spells.
    </p>
    <ul>
      <li>Circle of death (intensified, 2 charges, DC 29)</li>
      <li>Create greater undead (1 charge)</li>
      <li>Finger of death (improved heightened to 16th, 2 charges, DC
34)</li>
      <li>Soul bind (improved heightened to 16th, 2 charges, DC 34).
The soul trapped through the use of the soul bind spell is trapped in
the staff rather than a gem. Only by breaking the staff can the souls
be freed.
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 27th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Intensify Spell, Heighten Spell, Improved Heighten Spell,
circle of death, create greater undead, finger of death, soul bind; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 1,505,312 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 1,290,156 gp +
14,303 XP. </p>
    <a id="staff-of-planar-might"></a><p><span style={{
        "fontWeight": "bold"
      }}>Planar Might:</span> The
wielder of this powerful staff is immune to the effects of any planar
alignment traits, as well as the positive-dominant and
negative-dominant traits of a plane. It also allows use of the
following spells.
    </p>
    <ul>
      <li>Greater planar ally (1 charge)</li>
      <li>Greater planar binding (1 charge)</li>
      <li>Gate (1 charge)
      </li>
    </ul>
    <p>When using the greater planar ally power, the character must
still bargain with the called creature.
      <p>{`In addition to its other powers, a staff of planar might is a +5
outsider bane quarterstaff (roll 1d4 to determine what alignment of
outsiders the staff `}{`’`}{`s bane power works against: 1=chaotic, 2=evil,
3=good, 4=lawful). After all charges are used up from a staff of planar
might, it remains a +5 quarterstaff with no special abilities.`}</p>
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, gate, greater planar ally, greater planar binding,
protection from energy; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
460,000 gp. </p>
    <a id="staff-of-prism"></a><p><span style={{
        "fontWeight": "bold"
      }}>Prism:</span> This staff
allows use of the following spells.
    </p>
    <ul>
      <li>Prismatic sphere (extended, 1 charge, DC 25)</li>
      <li>Prismatic spray (extended, 1 charge, DC 22)</li>
      <li>Prismatic wall (extended, 1 charge, DC 23)
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Extend Spell, prismatic sphere, prismatic spray, prismatic
wall; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 326,812
gp. </p>
    <a id="staff-of-rapid-barrage"></a><p><span style={{
        "fontWeight": "bold"
      }}>Rapid Barrage:</span> Either
of the staff&#8217;s two powers can be activated as a free action (though the
staff may only be activated once per round).
    </p>
    <ul>
      <li>Magic missile (intensified, quickened, 1 charge, 5 missiles
dealing 10 points of damage each)</li>
      <li>Fireball (heightened to 6th, enhanced, quickened, 1 charge,
20d6 damage, DC 19)
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 25th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Enhance Spell, Heighten Spell, Intensify Spell, Quicken
Spell, fireball, magic missile; <span style={{
        "fontStyle": "italic"
      }}>Market
Price:</span> 417,750 gp. </p>
    <a id="staff-of-spheres"></a><p><span style={{
        "fontWeight": "bold"
      }}>Spheres:</span> This staff
allows use of the following spells.
    </p>
    <ul>
      <li>Freezing sphere (1 charge, DC 19)</li>
      <li>Resilient sphere (1 charge, DC 16)</li>
      <li>Telekinetic sphere (1 charge, DC 22)
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, freezing sphere, resilient sphere, telekinetic sphere; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 228,375 gp. </p>
    <a id="staff-of-walls"></a><p><span style={{
        "fontWeight": "bold"
      }}>Walls:</span> This staff
allows use of the following spells.
    </p>
    <ul>
      <li>Wall of iron (1 charge)</li>
      <li>Wall of stone (1 charge)</li>
      <li>Wall of force (1 charge)
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 30th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Extend Spell, extended wall of force, wall of iron, wall of
stone; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 275,625
gp. </p>
    <a id="staff-of-winter"></a><p><span style={{
        "fontWeight": "bold"
      }}>Winter:</span> This staff
allows the use of the following spells.
    </p>
    <ul>
      <li>Cone of cold (intensified, 2 charges, DC 28)</li>
      <li>Ice storm (intensified, 2 charges, DC 26)</li>
      <li>Freezing sphere (intensified, 2 charges, DC 29)</li>
      <li>Wall of ice (1 charge)
      </li>
    </ul>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 24th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Staff, Craft
Epic Staff, Extend Spell, Intensify Spell, cone of cold, ice storm,
freezing sphere, wall of ice; <span style={{
        "fontStyle": "italic"
      }}>Market
Price:</span> 292,500 gp.
    </p>
    <a id="epic-wondrous-items"></a><h3>WONDROUS ITEMS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
Anyone can use a wondrous item unless specified otherwise in the item&#8217;s
description.
    <a id="table-epic-wondrous-items"></a><table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>d%
          </th>
          <th style={{
            "width": "35%"
          }}>Wondrous Item
          </th>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold",
            "width": "15%"
          }}>Market
Price 
          </td>
        </tr>
        <tr className="odd-row">
          <td>01-02
          </td>
          <td>Horseshoes of the peerless steed 
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>217,000 gp 
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>03-04
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Mantle of
great stealth 
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>242,000 gp 
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>05-06
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Boots of
swiftness 
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>256,000 gp 
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>07-08
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Cabinet
of feasting
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>288,000 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>09-10
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Mantle of
epic spell resistance
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>290,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>11-13
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)"
          }}>Cloak of
epic resistance +6 
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>360,000 gp 
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>14-15
          </td>
          <td style={{
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Gate key 
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>378,000 gp 
          </td>
        </tr>
        <tr>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)",
            "color": "rgb(0, 0, 0)"
          }}>16-18
          </td>
          <td style={{
            "backgroundColor": "rgb(255, 255, 255)",
            "color": "rgb(0, 0, 0)"
          }}>Cloak
of epic resistance +7
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>490,000 gp 
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>19-21
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Belt
of epic strength +8
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>640,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>22-24
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Bracers of epic health +8
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>640,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>25-27
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Cloak
of epic charisma +8
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>640,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>28-30
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Cloak of epic resistance +8
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>640,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>31-33
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Gloves
of epic dexterity +8
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>640,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>34-36
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Headband of epic intellect
+8
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>640,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>37-39
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Periapt
of epic wisdom +8
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>640,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>40-42
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Amulet of epic natural
armor +6 
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>720,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>43-45
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Cloak
of epic resistance +9
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>810,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>46-48
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Amulet of epic natural
armor +7
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>980,000 gp 
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>49-51
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Belt
of epic strength +10
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>1,000,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>52-54
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Bracers of epic health +10
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>1,000,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>55-57
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Cloak
of epic charisma +10
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>1,000,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>58-59
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Cloak of epic resistance
+10
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>1,000,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>60-62
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Gloves
of epic dexterity +10 
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>1,000,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>63-65
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Headband of epic intellect
+10
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>1,000,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>66-68
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Periapt
of epic wisdom +10
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>1,000,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>69-71
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Bracers of epic armor +11
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>1,210,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>72-74
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Amulet
of epic natural armor +8
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>1,280,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>75-76
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Belt of epic strength +12
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>1,440,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>77-78
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Bracers
of epic armor +12
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>1,440,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>79-80
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Bracers of epic health +12
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>1,440,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>81-82
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Cloak
of epic charisma +12
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>1,440,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>83-84
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Gloves of epic dexterity
+12
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>1,440,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>85-86
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Headband
of epic intellect +12
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>1,440,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>87-88
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Periapt of epic wisdom +12
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>1,440,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>89-90
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Amulet
of epic natural armor +9
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>1,620,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>91-92
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Bracers of epic armor +13
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>1,690,000 gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>93-94
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Bracers
of epic armor +14
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>1,960,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>95-96</td>
          <td style={{
            "verticalAlign": "top"
          }}>Amulet of epic natural
armor +10
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>2,000,000 gp</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>97-98
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Bracers
of epic armor +15
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "width": "15%"
          }}>2,250,000
gp
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>99-100
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Bracers of relentless
might 
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "15%"
          }}>4,384,000 gp
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-wondrous-item-descriptions"></a><h5>Epic Wondrous Item Descriptions </h5>
Standard epic wondrous items are described below. 
    <a id="amulet-of-epic-natural-armor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Amulet of Epic Natural Armor:</span>&nbsp;
This amulet toughens the wearer&#8217;s body and flesh, giving him or her an
enhancement bonus to his or her natural armor of +6 or higher,
depending on the type of amulet.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, barkskin, creator&#8217;s caster level must be
three times the amulet&#8217;s bonus; <span style={{
        "fontStyle": "italic"
      }}>Market
Price:</span> 720,000 gp (+6), 980,000 gp (+7), 1,280,000 gp (+8),
1,620,000 gp (+9), 2,000,000 gp (+10); <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> -. </p>
    <a id="belt-of-epic-strength"></a><p><span style={{
        "fontWeight": "bold"
      }}>Belt of Epic Strength:</span>
This belt adds an enhancement bonus of +8 or higher to the wearer&#8217;s
Strength score.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, bull&#8217;s strength; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 640,000 gp (+8),
1,000,000 gp (+10), 1,440,000 gp (+12); <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 1 lb. </p>
    <a id="boots-of-swiftness"></a><p><span style={{
        "fontWeight": "bold"
      }}>Boots of Swiftness:</span>
These shoes grant their wearer a +6 enhancement bonus to Dexterity. The
wearer&#8217;s speed doubles (this does not stack with any magical or
supernatural enhancement to speed), he or she gains the evasion ability
(as the rogue class feature), and the wearer&#8217;s jumping distance is not
limited by his or her height. The wearer gains a +20 competence bonus
on Balance, Climb, Jump, and Tumble checks. Three times per day, the
wearer can utter a command word to activate the boots&#8217; haste power (as
the haste spell, lasts 20 rounds).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, cat&#8217;s grace, expeditious retreat, haste,
jump; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 256,000
gp; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 1 lb. </p>
    <a id="bracers-of-epic-armor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Bracers of Epic Armor:</span>
These items surround the wearer with an invisible but tangible field of
force, granting him or her an armor bonus of +11 or higher, just as
though he or she were wearing armor. Both bracers must be worn for the
magic to be effective.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, mage armor, creator&#8217;s caster level must be
twice the bracers&#8217; bonus; <span style={{
        "fontStyle": "italic"
      }}>Market
Price:</span> 1,210,000 (+11), 1,440,000 (+12), 1,690,000 gp (+13),
1,960,000 (+14), 2,250,000 gp (+15); <span style={{
        "fontStyle": "italic"
      }}>Weight:</span>
1 lb. </p>
    <a id="bracers-or-epic-health"></a><p><span style={{
        "fontWeight": "bold"
      }}>Bracers of Epic Health:</span>
These grant the wearer an enhancement bonus of +8 or higher to
Constitution.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, endurance; <span style={{
        "fontStyle": "italic"
      }}>Market
Price:</span> 640,000 gp (+8), 1,000,000 gp (+10), 1,440,000 gp (+12); <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 1 lb. </p>
    <a id="bracers-of-relentless-might"></a><p><span style={{
        "fontWeight": "bold"
      }}>Bracers of Relentless Might:</span>
These bracers grant a +12 enhancement bonus to the wearer&#8217;s Strength
and Constitution. The wearer is treated as two size categories larger
than normal (to a maximum of Colossal) for purposes of combat-related
opposed checks that apply a modifier based on size, such as bull rush,
grapple, and trip.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, bull&#8217;s strength, endurance, enlarge; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 4,384,000 gp; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 1 lb. </p>
    <a id="cabinet-of-feasting"></a><p><span style={{
        "fontWeight": "bold"
      }}>Cabinet of Feasting:</span>
This cabinet has the ability to produce a feast for up to forty people,
three times per day. Merely opening the doors of the cabinet reveals
platters of food of all types and flavors of the very freshest sort.
The meal has all the qualities and benefits of that produced by a
heroes&#8217; feast spell.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 40th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, heroes&#8217; feast; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 288,000 gp; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 20 lb. </p>
    <a id="cloak-of-epic-charisma"></a><p><span style={{
        "fontWeight": "bold"
      }}>Cloak of Epic Charisma:</span>
When worn, this cloak grants a +8 or higher enhancement bonus to a
character&#8217;s Charisma score.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, charm monster; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 640,000 gp (+8),
1,000,000 gp (+10), 1,440,000 gp (+12); <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 1 lb. </p>
    <a id="cloak-of-epic-resistance"></a><p><span style={{
        "fontWeight": "bold"
      }}>Cloak of Epic Resistance:</span>
These garments offer magic protection in the form of a +6 or higher
resistance bonus on all saving throws (Fortitude, Reflex, and Will).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, resistance, creator&#8217;s caster level must be
three times the cloak&#8217;s bonus; <span style={{
        "fontStyle": "italic"
      }}>Market
Price:</span> 360,000 (+6), 490,000 (+7), 640,000 gp (+8), 810,000
(+9), 1,000,000 gp (+10); <span style={{
        "fontStyle": "italic"
      }}>Weight:</span>
1 lb. </p>
    <a id="gate-key"></a><p><span style={{
        "fontWeight": "bold"
      }}>Gate Key:</span> When
properly operated, the gate key can be used to attune any bounded
space, such as a doorway or a cave opening, to another bounded space on
another plane of existence previously visited by the wielder. When two
bounded spaces are attuned, an interdimensional portal springs up at
each location, and the two portals are connected. When the wielder
creates a pair of portals, he or she also establishes the necessary key
that travelers must have to access the portal. Possible keys include
nothing, a pearl, a particular hair color, or even the gate key itself.
Up to sixty different pairs of portals can be attuned in this manner.
Once all sixty pairs of portals are created, the key cannot create
additional gates, though it may still be the necessary key to access
some or all of the portals. To any creature without the proper key, the
interdimensional portals are not visible (through true seeing or
similar magic reveals their presence).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, gate; <span style={{
        "fontStyle": "italic"
      }}>Market
Price:</span> 378,000 gp; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span>
1 lb. </p>
    <a id="gloves-of-epic-dexterity"></a><p><span style={{
        "fontWeight": "bold"
      }}>Gloves of Epic Dexterity:</span>
These gloves add an enhancement bonus of +8 or higher to the wearer&#8217;s
Dexterity score.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, cat&#8217;s grace; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 640,000 gp (+8),
1,000,000 gp (+10), 1,440,000 gp (+12); <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> -. </p>
    <a id="headband-of-epic-intellect"></a><p><span style={{
        "fontWeight": "bold"
      }}>Headband of Epic Intellect:</span>
This headband adds an enhancement bonus of +8 or higher to the wearer&#8217;s
Intelligence score.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, commune or legend lore; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 640,000 gp (+8),
1,000,000 gp (+10), 1,440,000 gp (+12); <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> -. </p>
    <a id="horseshoes-of-the-peerless-steed"></a><p><span style={{
        "fontWeight": "bold"
      }}>Horseshoes of the Peerless
Steed:</span> These horseshoes magically adhere to the feet of any
hoofed creature. Anyone riding the creature gains a +10 competence
bonus on Ride checks and is treated as having ranks in the appropriate
Ride skill (and thus does not take the -5 penalty for riding an
unfamiliar mount). The horseshoes grant the creature (or its rider, as
appropriate) the effects of the Trample, Ride-By Attack, and Spirited
Charge feats. The wearer of the horseshoes gains spell resistance 32
against enchantment effects. In addition, the ground speed of the
creature wearing the horseshoes doubles.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, Ride skill, haste, spell resistance; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 217,000 gp; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 1 lb. each. </p>
    <a id="mantle-of-epic-spell-resistance"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mantle of Epic Spell
Resistance:</span> This garment is worn over normal clothing or armor.
It grants the wearer spell resistance 40.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 29th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, spell resistance; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 290,000 gp. <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 1 lb. </p>
    <a id="mantle-of-great-stealth"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mantle of Great Stealth:</span>
The wearer of this cloak gains a +30 bonus on Hide and Move Silently
checks. The wearer&#8217;s outline is blurry and indistinct, granting
concealment (20% miss chance) at all times (as the blur spell). The
mantle also grants nondetection to its wearer (as the spell).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, blur, invisibility, nondetection, silence; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 242,000 gp; <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> 1 lb. </p>
    <a id="periapt-of-epic-wisdom"></a><p><span style={{
        "fontWeight": "bold"
      }}>Periapt of Epic Wisdom:</span>
This large pearl set on a chain grants an enhancement bonus of +8 or
higher to the wearer&#8217;s Wisdom score.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Wondrous Item,
Craft Epic Wondrous Item, commune or legend lore; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 640,000 gp (+8),
1,000,000 gp (+10), 1,440,000 gp (+12); <span style={{
        "fontStyle": "italic"
      }}>Weight:</span> -.</p>
    <p> </p>
    <p> </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      